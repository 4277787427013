<route>
  {
  "name": "verify",
  "meta": {
  "auth": false
  }
  }
</route>
<template>
  Redirecting...
</template>

<script>
import { authManagementClient } from '@/plugins/feathersClient';

export default {
  name: 'verify',
  layout: 'auth',
  async created() {
    // Try get verfiy token (from email's link)
    const { token } = this.$route.query;

    if (token) {
      // Logout for email exist flow (need to reach login)
      if (localStorage['feathers-jwt']) {
        try {
          await this.$store.dispatch('auth/logout');
        } catch (error) {
          console.log('Logout error');
        }
        this.$notify.info('Reason: try to register', 'You have been logged out!');
      }

      try {
        await authManagementClient.verifySignupLong(token);
      } catch (err) {
        this.$notify.parseFeathersErrors(err);
        this.hasVerifyToken = false;
      }

      this.$notify.success('User verified! Please Log In!');
    } else {
      this.$notify.success('Token not found! Text to our manager.');
    }

    this.$router.push({
      name: 'login'
    });
  }
}
</script>

<style>

</style>